/* Body */
.overflow-scroll {
  -webkit-overflow-scrolling: touch;
}

.no-scroll {
  position: fixed;
  overflow: hidden;
}

.no-pointer-events {
  pointer-events: none;
}

/* Button */
.ant-btn {
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-btn {
  height: 33px !important;
}

.ant-btn span {
  text-shadow: none;
}

.ant-btn-sm {
  height: 24px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  padding: 0px 10px !important;
  color: #6b6b6b !important;
}

.ant-btn-sm.ant-btn-primary {
  color: #fff !important;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.ant-btn-lg {
  height: 40px !important;
}

.no-margin.ant-btn > .anticon + span {
  margin-left: 0px !important;
}

.ant-btn > .ant-btn-loading-icon {
  padding-right: 8px;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
}

/* Badge */

.ant-badge-count {
  background-color: #F5A623 !important;
  color: #000 !important;
}

/* Header */

.ant-layout-header {
  line-height: normal !important;
}

/* List */

.ant-list-item-action {
  margin-left: 12px !important;
}

/* Slider */
.ant-slider-rail {
  background-color: #f3f3f3 !important;
}

.ant-slider-track {
  background-color: #00af66 !important;
}

.ant-slider-handle {
  border-color: #00af66 !important;
}

/* Upload */
.ant-upload {
  width: 100% !important;
}

/* Modal */
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}

.full-page-modal .ant-modal {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.full-page-modal .ant-modal-content {
  height: 100%;
}

.full-page-modal .ant-modal-body {
  height: 100%;
}

.shadowless-modal .ant-modal-content {
  box-shadow: none !important;
}

.brella-video-call-modal .ant-modal-footer {
  border-top: 0px !important;
}

/* Timeline */
.ant-timeline-item-content {
  margin: 0 0 16px 18px !important;
}

/* Tabs */

.ant-tabs-nav .ant-tabs-tab {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #818283 !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #000 !important;
}

.br-sidebar-tabs .ant-tabs-nav {
  padding: 0px 10px;
}

.br-sidebar-tabs .ant-tabs-nav .ant-tabs-tab {
  min-width: 3ch;
  text-align: center;
  max-width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 16px 0 0;
}

.br-sidebar-tabs .ant-tabs-bar {
  margin: 0;
}

.ant-tabs {
  overflow: hidden !important;
}

.sidebar .ant-tabs-tabpane {
  height: calc(100vh - 108px);
}

/* Typograhpy */

.ant-typography blockquote {
  margin-top: 1em;
  padding: 0 0 0 .6em;
  border-left: 4px solid hsla(0,0%,39.2%,.2);
  opacity: .85;
}

/* Checkbox */

.floated-checkbox {
  clear: both;
  padding: 8px 0px !important;
  line-height: 1.2 !important;
}

.floated-checkbox .ant-checkbox {
  float: left;
}

.floated-checkbox .ant-checkbox + span {
  width: calc(100% - 20px);
  float: left;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.ant-checkbox-inner {
  border-radius: 4px;
}

span.ant-radio + * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-checkbox-wrapper + span, .ant-checkbox + span {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Notification */

.ant-notification-notice {
  padding: 28px 24px !important;
}

.ant-notification-notice.no-message .ant-notification-notice-message {
  display: none !important;
}

/* Menu */

.ant-menu-item {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu-item + * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:after {
  transform: scaleY(1);
  opacity: 1;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important;
}

.ant-menu-vertical .ant-menu-item svg {
  margin-right: 12px;
}

@media only screen and (max-width: 990px) {
  .ant-menu-vertical .ant-menu-item svg {
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .ant-menu-item .anticon {
    margin-right: 0px !important;
  }
}

/* Collapse */

.small-collapse .ant-collapse-header {
  padding: 10px 0px !important;
}

.small-collapse .ant-collapse-header .ant-collapse-arrow {
  position: absolute !important;
  right: 0px !important;
  left: auto !important;
}

.small-collapse .ant-collapse-content-box {
  padding: 0px !important;
}

.small-collapse .ant-collapse-item {
  border: 0px !important;
}

.br-announcement-popover {
  width: 388px;
  max-width: 388px;
  position: fixed;
}

.br-announcement-popover .ant-popover-inner-content {
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .br-announcement-popover {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .schedule .ant-timeline-item-content {
    padding: 2px 0 0 22px;
  }

  .schedule .ant-timeline-item {
    padding: 0px;
    margin: 32px 0px 0px 0px;
  }

  .schedule .ant-timeline-item-head-custom {
    top: 0px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0px !important;
  }
}
