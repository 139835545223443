.react-multi-carousel-list:hover .react-multiple-carousel__arrow {
  background: rgba(255,255,255,0.5);
}

.react-multiple-carousel__arrow {
  background: rgba(255,255,255,0.1);
  box-shadow: 0 2px 2px rgba(0,0,0,0.05), 0 5px 10px rgba(0,0,0,0.1);
}

.react-multiple-carousel__arrow:hover {
  background: rgba(255,255,255,0.8);
}

.react-multiple-carousel__arrow::before {
  color: #333;
}

.react-multi-carousel-dot-list {
  pointer-events: none;
}

.react-multi-carousel-dot {
  pointer-events: auto;
}

.react-multi-carousel-dot button {
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  background: transparent;
}

.react-multi-carousel-dot--active button {
  background: #fff;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}