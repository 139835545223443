.wysiwyg-content {
  margin: 0px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wysiwyg-content img {
  max-width: 100% !important;
  display: block;
}

.wysiwyg-content span {
  font: inherit !important;
  color: inherit !important;
}
